import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  .flip-box .wallet strong {
    font-size: 1rem;
    line-height: 2rem;
  }

  .flip-box .wallet small {
    font-size: 0.85rem;
  }

  .flip-box {
    height: 170px;
    display: none;
  }

  .wallet {
    padding-top: 20px;
  }

  .wallet p {
    padding-top: 3px;
    font-size: 0.82rem !important;
  }

  .wallet a {
    position: relative;
    display: inline-block;
  }

  .wallet a img {
    position: absolute;
  }

  ${vars.desktop.mediaQuery} {
    .flip-box .wallet strong {
      margin-top: -1px;
      font-size: 1.15rem;
      line-height: 1.4rem;
    }

    .flip-box .wallet a {
      margin-top: 3px;
      font-size: 0.75rem;
    }

    .flip-box .wallet a img {
      top: 7px;
      right: -20px;
      height: 12px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .flip-box .wallet strong {
      margin-top: -2px;
    }

    .flip-box .wallet a {
      font-size: 0.8rem;
    }

    .flip-box .wallet a img {
      height: 13px;
      top: 2px;
      right: -22px;
    }
  }
`;

const toClass = (tag) => tag.toLowerCase().replace("$", "").replace(/\s/g, "-");

const Tagged = (tags) => styled.div`
  ul {
    padding-bottom: 10px;
    font-size: 0.75rem;
  }

  li {
    margin-right: 10px;
    padding: 0 9px;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 15px;
  }

  ${vars.desktop.mediaQuery} {
    width: calc(100% + 23px);

    ul {
      padding-bottom: 13px;
    }
  }

  ${vars.mobile.mediaQuery} {
    ul li {
      margin-bottom: 4px;
      padding: 3px 12px 2px;
      font-size: 0.8rem;
      font-weight: 700;
      border-radius: 13px;
    }
  }

  ${tags
    .map((tag) => {
      tag = toClass(tag);
      return `
        &.${tag} li.${tag} {
          color: white;
          background: ${vars.colors.blue} !important;
        }

        ${vars.desktop.mediaQuery} {
          &.${tag} .flip-box.${tag} {
            width: calc(33% - 15px);
            display: inline-block;
          }
        }
      
        ${vars.mobile.mediaQuery} {
          &.${tag} .flip-box.${tag} {
            display: block;
          }
        }
      `;
    })
    .join("\n\n")}
`;

export { Root, Tagged };
